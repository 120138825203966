@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('./assets/css/variables.css');

html,
body {
    height: 100%;
    background-color: var(--body-background-color);
    color: var(--primary-text-color);
    font-size: 16px;
    font-family: var(--font-family);
    padding-top: 0.5rem;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.blue-text{
    color: var(--sub-text);
    font-size: 19.2px;
}



/* ********* Font Family ********* */
.font-family {
    font-family: var(--font-family) !important;
}


/* ********* Font Sizes ********* */
.fs-10 {
  font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-26 {
  font-size: 26px !important;
}
.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}


/* ********* Font Weights ********* */
.fw-medium {
  font-weight: 600 !important;
}


/* ********* Headers ********* */
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: var(--font-family);
  color: var(--sub-text);
}

/* p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text-dark);
} */

/* Solomon */
/* .spinner-grow {
  width: 10px;
  height: 10px;
} */

.btn {
  border: 0;
  padding: 0;
}

.fw-500 {
  font-weight: 500;
}

.fw-300 {
  font-weight: 500;
  font-size: 14px;
}

small {
  font-size: 10px;
}

tbody tr {
  line-height: 1.8em;
}

.dashboard-card {
  min-height: 200px;
  /* min-width: 270px; */
  width: 100%;
  /* max-width: 270px; */
  padding: 16px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 10px;
  display: block;
  transition: 0.4s;
  box-shadow: 0px 1px 8.8px rgba(0, 103, 143, 0.06);
  text-align: center;
  text-decoration: none;
  color: var(--main-text-color);
}

.am-dashboard-card {
  /* min-width: 270px; */
  width: 100%;
  /* max-width: 270px; */
  padding: 16px;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 10px;
  display: block;
  transition: all 1s ease-in-out;
  box-shadow: 0px 1px 8.8px rgba(0, 103, 143, 0.06);
  text-align: center;
  text-decoration: none;
  color: var(--main-text-color);
}

.am-dashboard-closed-card {
  transition: all 1s ease-in-out;
  height: 20px !important;
}

.act-card {
  max-height: 250px !important;
}

.card-time {
  font-size: 90%;
}

.dash-action-wrapper {
  display: flex;
  /* max-width:100%; */
  margin-top: 5rem;
  /* margin-bottom: 3rem; */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}




.dac-icon-wrapper {
  background: #F2F7FF;
  border-radius: 14px;
  padding: 17px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
}

.one {
  background: #FFC60025;
}

.two {
  background: #3AD29F25;
}

tbody tr>th {
  color: #2a2a2a !important;
}

.table>:not(caption)>*>* {
  font-size: small;
  padding: .3em .5em;
}

/* h2,
h3,
h4,
h5,
h6,
p,
span,
small {
  color: #292E47;
} */

.input-group-text {

background-color: #fff;

}


h2{
    font-size: 49px;
}

.spinner-grow {
    display: inline-block;
    width: 10px;
    height: 10px;
    vertical-align: -0.125em;
    /* background-color: #611F69; */
    border-radius: 50%;
    opacity: 0;
    -webkit-animation: .75s linear infinite spinner-grow;
    animation: .75s linear infinite spinner-grow;
}

.dash-card{
    box-shadow: 1.3125px 1.3125px 6.5625px 2.625px rgba(0, 0, 0, 0.03);
    border-radius: 20.3438px;
    padding: 20px;
}

.dash-box{
    /* opacity: 0.25; */
    border-radius: 10px; 
    width: 36px;
    height: 36px;
    justify-content: center;
    text-align: center;
}

.sec-font{
    font-family: var(--secondary-font-family);
}

footer{
    background-color: var(--footer-background-color);
    color: #CCD5E3;
    padding-top: 70px;
    padding-bottom: 40px;
   
}



.footer-blue{
    color: #6480A1;
}


.d3-tip {
    line-height: 1;
    font-weight: bold;
    padding: 6px 8px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    pointer-events: none;
}
.tooltip {
    opacity: 1 !important;
}
path {
    cursor: pointer;
}




.material-symbols-outlined {
    color: var(--text-dark);
    border-radius: 10px;
}