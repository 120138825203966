:root{
    --primary-color: #1D1C1D;
    --primary-color-hover: #1C2F44;
    --primary-text-color: #949494;
    --header-text-color: #1D1C1D;
    --sub-text: #203E7A;
    --body-background-color: #FFFDFA;
    /* --link-color: #50CA45;
    --link-color-hover: #2ea324; */
    --font-family: 'Roboto', 'Inter', Arial, Helvetica, sans-serif;
    --secondary-font-family: 'Poppins', 'Inter', Arial, Helvetica, sans-serif;
    --footer-background-color: #1D1C1D;
    /* --success-color: #3FB948; */
    /* --card-bg-color:#fff */
    
    --text-green: #4ECB71;
    --text-dark: #212121;
    --text-dim: #AEAEAE;
    
}